/**
 * @module M/impl/format/GeoJSON
 */
import { isNullOrEmpty, generateRandom } from 'M/util/Utils';
import Feature from 'M/feature/Feature';
import OLFormatGeoJSON from 'ol/format/GeoJSON';
import { get as getProj } from 'ol/proj';
import OLStyle from 'ol/style/Style';
import OLStyleIcon from 'ol/style/Icon';

/**
  * @classdesc
  * Implementación de la clase GeoJSON. GeoJSON es un formato para codificar una variedad
  * de estructuras de datos geográficos.
  *
  * @api
  * @extends {ol.format.GeoJSON}
  */
class GeoJSON extends OLFormatGeoJSON {
  /**
    * Constructor principal de la clase.
    * @constructor
    * @param {olx.format.GeoJSONOptions} options Opciones del GeoJSON.
    * - dataProjection: Proyección de datos predeterminada. Por defecto "EPSG:4326".
    * - featureProjection: Proyección de los objetos geográficos leídos o escritos por el formato.
    * - geometryName: Nombre de la geometría que se utilizará al crear objetos geográficos.
    * - extractGeometryName: Si se establece en verdadero, el lector de GeoJSON buscará ese campo
    * para establecer el nombre de la geometría. Si este campo se establece
    * en verdadero y se proporciona
    * un nombre de geometría, el nombre de geometría tendrá prioridad. Por defecto falso.
    *
    * @api
    */
  constructor(options = {}) {
    super(options);
    this.mapProj = options.featureProjection;
  }

  /**
    * Este método obtiene el objetos geográficos a partir de un objeto GeoJSON.
    *
    * @function
    * @param {Object} object Objeto GeoJSON.
    * @param {Object} options Opciones.
    * @returns {M.Feature} Objetos geográficos.
    * @public
    * @api
    */
  readFeatureFromObject(object, options) {
    const geoJSONFeature = object;
    const feature = super.readFeatureFromObject(geoJSONFeature, options);
    // geometry
    if (this.geometryName_) {
      feature.setGeometryName(this.geometryName_);
    }
    // id
    if (!isNullOrEmpty(geoJSONFeature.id)) {
      feature.setId(geoJSONFeature.id);
    } else {
      feature.setId(generateRandom('geojson_'));
    }
    // properties
    if (geoJSONFeature.properties) {
      feature.setProperties(geoJSONFeature.properties);
    }
    // click function
    if (geoJSONFeature.click) {
      feature.click = geoJSONFeature.click;
    }
    // vendor parameters
    if (geoJSONFeature.properties && geoJSONFeature.properties.vendor
        && geoJSONFeature.properties.vendor.mapea) {
      // icons
      if (geoJSONFeature.properties.vendor.mapea.icon) {
        GeoJSON.applyIcon(feature, geoJSONFeature.properties.vendor.mapea.icon);
      }
    }
    return feature;
  }

  /**
    * Este método escribe un objetos geográficos en un objeto GeoJSON.
    *
    * @function
    * @param {M.Feature} feature Objetos geográficos a escribir.
    * @param {Object} optionsParameters Opciones.
    * @returns {Object} Objeto GeoJSON.
    * @public
    * @api
    */
  writeFeatureObject(feature, optionsParameters) {
    const options = optionsParameters;
    const object = {
      type: 'Feature',
    };

    const id = feature.getId();
    if (id) {
      object.id = id;
    }
    const geometry = feature.getGeometry();
    if (geometry) {
      object.geometry = super.writeGeometryObject(geometry, options);
    } else {
      object.geometry = null;
    }
    const properties = feature.getProperties();
    delete properties[feature.getGeometryName()];
    if (!isNullOrEmpty(properties)) {
      object.properties = properties;
    } else {
      object.properties = null;
    }

    if (!isNullOrEmpty(feature.click)) {
      object.click = feature.click;
    }
    return object;
  }

  /**
    * Este método obtiene la proyección a partir de un objeto GeoJSON.
    *
    * @function
    * @param {Object} object Objeto GeoJSON.
    * @returns {String} Proyección obtenida del objeto GeoJSON, si no
    * obtiene ninguna devuelve por defecto EPSG:4326.
    * @public
    * @api
    */
  static readProjectionFromObject(object) {
    let projection;
    const geoJSONObject = object;
    const crs = geoJSONObject.crs;
    if (crs) {
      if (crs.type === 'name') {
        projection = getProj(crs.properties.name);
      } else if (crs.type === 'EPSG') {
        // 'EPSG' is not part of the GeoJSON specification, but is generated by
        // GeoServer.
        // TODO: remove this when http://jira.codehaus.org/browse/GEOS-5996
        // is fixed and widely deployed.
        projection = getProj(`EPSG:${crs.properties.code}`);
      } else {
        projection = null;
        throw new Error(`Unknown crs.type: ${crs.type}`);
      }
    } else {
      projection = 'EPSG:4326';
    }
    return projection;
  }

  /**
    * Este método establece el estilo del icono de un objetos geográficos.
    *
    * @function
    * @param {M.Feature} feature Objetos geográficos.
    * @param {Object} icon Objeto con las opciones del icono.
    * @public
    * @api
    */
  static applyIcon(feature, icon) {
    const imgIcon = document.createElement('IMG');
    imgIcon.src = icon.url;
    imgIcon.width = icon.width;
    imgIcon.height = icon.height;
    imgIcon.crossOrigin = 'anonymous';

    let imgAnchor;
    if (icon.anchor && icon.anchor.x && icon.anchor.y) {
      imgAnchor = [icon.anchor.x, icon.anchor.y];
    }
    feature.setStyle(new OLStyle({
      image: new OLStyleIcon({
        // 'src': icon.url
        img: imgIcon,
        imgSize: [icon.width, icon.height],
        anchor: imgAnchor,
      }),
    }));
  }

  /**
    * Este método escribe una lista de objetos geográficos en objetos GeoJSON.
    *
    * @function
    * @param {Array<M.Feature>} features Lista de objetos geográficos.
    * @returns {Array<Object>} Lista de objetos GeoJSON.
    * @public
    * @api
    */
  write(features) {
    return features.map((feature) => this.writeFeatureObject(feature.getImpl().getOLFeature()));
  }

  /**
    * Este método lee objetos geográficos de una lista de objetos GeoJSON.
    *
    * @function
    * @param {Object} geojson Objeto GeoJSON.
    * @param {Array<Object>} geojsonFeatures Lista de objetos GeoJSON.
    * @param {M.Projection} projection Proyección.
    * @return {Array<M.Feature>} Lista de objetos geográficos.
    * @public
    * @api
    */
  read(geojson, geojsonFeatures, projection) {
    let features = [];
    let dstProj = projection.code;
    if (isNullOrEmpty(dstProj)) {
      if (!isNullOrEmpty(projection.featureProjection)) {
        dstProj = getProj(projection.featureProjection.getCode());
      } else {
        dstProj = getProj(projection.getCode());
      }
    }
    const srcProj = GeoJSON.readProjectionFromObject(geojson);
    features = geojsonFeatures.map((geojsonFeature) => {
      const id = geojsonFeature.id;
      const feature = new Feature(id, geojsonFeature);
      const olFeature = feature.getImpl().getOLFeature();
      if (olFeature.getGeometry() !== null) {
        const newGeometry = olFeature.getGeometry().transform(srcProj, dstProj);
        olFeature.setGeometry(newGeometry);
      }

      return feature;
    });
    return features;
  }
}

export default GeoJSON;
